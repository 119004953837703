import ModalClient from 'pagesDossierClient/Client/Modal';
import ModalFournisseur from 'pagesDossierClient/fournisseurs/Modal';
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form';

const ClientModal = ({isModalOpen, closeModal,refetch,refetchCodesTiers,isClient}) => {
    

    const methods = useForm()
  return (
    <div>
        {isModalOpen && isClient &&
        <FormProvider {...methods}>
            
                <ModalClient
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onDossierCreated={closeModal}
                    retour={false}
                    refetch={refetch}
                    refetchCodesTiers={refetchCodesTiers}
                >
                    <h2
                    style={{ color: ' #009688', fontSize: '20px', fontWeight: 700 }}
                    >
                    &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
                    </h2>
                </ModalClient>
        </FormProvider>
        }
        {isModalOpen && !isClient &&
        <FormProvider {...methods}>
            
                <ModalFournisseur
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onDossierCreated={closeModal}
                    retour={false}
                    refetch={refetch}
                    refetchCodesTiers={refetchCodesTiers}
                >
                    <h2
                    style={{ color: ' #009688', fontSize: '20px', fontWeight: 700 }}
                    >
                    &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
                    </h2>
                </ModalFournisseur>
        </FormProvider>
        }
    </div>
  )
}

export default ClientModal