import { ApiMananger } from "actions/rtkquery";
import { 
        GET_BILAN_COMPTABLE_API_ROUTE
    } from "routes/api/endpoints";

const bilanApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

        getBilanComptable: build.query({
            query: ({id,dossier,annee}) => `${GET_BILAN_COMPTABLE_API_ROUTE}?id=${id}&dossier=${dossier}&annee=${annee}`,
            providesTags: ["INVALIDATE_BILANCOMPTABLE"],
        }),

    })
})

export const {
    useGetBilanComptableQuery
}= bilanApi