import { ApiMananger } from "actions/rtkquery";
import { 
        GET_GRANDLIVRE_GENERAL_API_ROUTE, 
        GET_BALANCE_GENERAL_API_ROUTE, 
        GET_BALANCE_AUXILIAIRE_API_ROUTE,
        GET_BALANCE_AGEE_API_ROUTE
    } from "routes/api/endpoints";

const comptaApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

        getGrandLivreGeneral: build.query({
            query: ({id,dossier}) => `${GET_GRANDLIVRE_GENERAL_API_ROUTE}?id=${id}&dossier=${dossier}`,
            providesTags: ["INVALIDATE_LIVREJOURNAL"],
        }),

        getBalanceGeneral: build.query({
            query: ({id,dossier,annee}) => `${GET_BALANCE_GENERAL_API_ROUTE}?id=${id}&dossier=${dossier}&annee=${annee}`,
            providesTags: ["INVALIDATE_LIVREJOURNAL"],
        }),

        getBalanceAuxiliaire: build.query({
            query: ({id,dossier}) => `${GET_BALANCE_AUXILIAIRE_API_ROUTE}?id=${id}&dossier=${dossier}`,
            providesTags: ["INVALIDATE_LIVREJOURNAL"],
        }),

        getBalanceAgee: build.query({
            query: ({id,dossier}) => `${GET_BALANCE_AGEE_API_ROUTE}?id=${id}&dossier=${dossier}`,
            providesTags: ["INVALIDATE_LIVREJOURNAL"],
        }),

    })
})

export const {
    useGetGrandLivreGeneralQuery,
    useGetBalanceGeneralQuery,
    useGetBalanceAuxiliaireQuery,
    useGetBalanceAgeeQuery
}= comptaApi