import React from 'react'
import BarLoader from "react-spinners/BarLoader"

export default function Bilan_Passif({bilan_comptable,isLoadingCode,load}) {
  return (
    <>
      <tr>
        <th>Libelle de quelque chose</th>
        <th>Net Exercice N</th>
        <th>Exercice(N-1)</th>
      </tr>
      {
      !isLoadingCode && !load?<>
        <tr>
          <td style={{fontWeight:"bold"}}>CAPITAUX PROPRES ET RESSOURCES ASSIMILEES</td>
          <td>-</td>
          <td>-</td>
        </tr>
        {/********************************************* Capitaux Propres*************************************************** */}
        {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          Object.keys(bilan_comptable["PASSIF"]["CAPITAUX"]["CAPITAUX PROPRES ET RESSOURCES ASSIMILEES"]).map((title,index) =>{
            if(!title.includes("Total Capitaux")){
              return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["PASSIF"]["CAPITAUX"]["CAPITAUX PROPRES ET RESSOURCES ASSIMILEES"][title]}</td>
                  <td>-</td>
                </tr>
              )
            }
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          <tr>
            <th>Total Capitaux</th>
            <th>{bilan_comptable["PASSIF"]["CAPITAUX"]["CAPITAUX PROPRES ET RESSOURCES ASSIMILEES"]["Total Capitaux"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}

        {/********************************************* Dettes Financières *************************************************** */}
        <tr>
          <td style={{fontWeight:"bold"}}>DETTES FINANCIERES ET RESSOURCES ASSIMILEES</td>
          <td>-</td>
          <td>-</td>
        </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          Object.keys(bilan_comptable["PASSIF"]["DETTES FINANCIERES"]["DETTES FINANCIERES ET RESSOURCES ASSIMILEES"]).map((title,index) =>{
            if(!title.includes("Total Dettes")){
              return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["PASSIF"]["DETTES FINANCIERES"]["DETTES FINANCIERES ET RESSOURCES ASSIMILEES"][title]}</td>
                  <td>-</td>
                </tr>
              )
            }
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          <tr>
            <th>Total Dettes</th>
            <th>{bilan_comptable["PASSIF"]["DETTES FINANCIERES"]["DETTES FINANCIERES ET RESSOURCES ASSIMILEES"]["Total Dettes"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}
        
        {/********************************************* PASSIF CIRCULANT *************************************************** */}
        <tr>
          <td style={{fontWeight:"bold"}}>PASSIF CIRCULANT</td>
          <td>-</td>
          <td>-</td>
        </tr>
        {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          Object.keys(bilan_comptable["PASSIF"]["PASSIF CIRCULANT"]).map((title,index) =>{
            if(!title.includes("Total PASSIF CIRCULANT")){
              return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["PASSIF"]["PASSIF CIRCULANT"][title]}</td>
                  <td>-</td>
                </tr>
              )
            }
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          <tr>
            <th>Total PASSIF CIRCULANT</th>
            <th>{bilan_comptable["PASSIF"]["PASSIF CIRCULANT"]["Total PASSIF CIRCULANT"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}

        {/********************************************* TRESORERIE PASSIF*************************************************** */}
        <tr>
          <td style={{fontWeight:"bold"}}>TRESORERIE PASSIF</td>
          <td>-</td>
          <td>-</td>
        </tr>
        {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          Object.keys(bilan_comptable["PASSIF"]["TRESORERIE PASSIF"]).map((title,index) =>{
            if(!title.includes("Total TRESORERIE PASSIF")){
              return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["PASSIF"]["TRESORERIE PASSIF"][title]}</td>
                  <td>-</td>
                </tr>
              )
            }
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
          <tr>
            <th>Total TRESORERIE PASSIF</th>
            <th>{bilan_comptable["PASSIF"]["TRESORERIE PASSIF"]["Total TRESORERIE PASSIF"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}
          
          {
            bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?(
              <tr>
                <td style={{fontWeight:"bold"}}>Ecart de conversion-Passif</td>
                <td>{bilan_comptable["PASSIF"]["Ecart de conversion-Passif"]}</td>
                <td>-</td>
              </tr>
            ):""
          }

          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'PASSIF')?
            <tr>
              <th>Total PASSIF</th>
              <th>{bilan_comptable["PASSIF"]["Total Passif"]}</th>
              <th>-</th>
            </tr>:""
            }

      </>:
      <tr className="global fournisseur-detail-container"
        style={{
          backgroundColor: 'white',
          minHeight: '700px',
          borderRadius: '20px',
          padding: '15px',
          opacity: '0.5',
          position: 'relative'
          
        }}>
          <td 
            style={{
              position: 'absolute',
              top: '70%',
              left: '50%',
              colspan:"3"
            }}
          >
            <BarLoader />
          </td>
      </tr>
      }
    </>
  )
}
