import React, { useRef, useState, useEffect } from 'react'
import HeaderFirst from 'utils/HeaderFirst'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import { usePermissions } from 'hooks/usePermissions';
import { useParams } from 'react-router-dom';
import { useGetBilanComptableQuery } from 'actions/sage_clone/bilan/queries';
import Bilan_Actif from './Bilan_Actif';
import Bilan_Passif from './Bilan_Passif';

export default function Bilan() {

  const {clientID} = useParams()
  const [year,setYear] = useState(2025);
  const [load, setLoad] = useState(false)
  const { data: bilan_comptable={}, isLoading: isLoadingCode, refetch } = useGetBilanComptableQuery({
    id: 11,
    dossier: clientID,
    annee: year
  })
  const [selectedTiers, setSelectedTiers] = useState('ACTIF');
  const permissions = usePermissions();
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Bilan Comptable table',
    sheet: 'BilanComptable'
  })

  // pour pdf
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const myRef = useRef(null);

  useEffect(function () {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch()
    if(!isLoadingCode)
      setLoad(false)
  }, [isLoadingCode, bilan_comptable])
  

  /*var filteredBalanceAgee = []
  if (bilan_comptable &&
    Object.prototype.hasOwnProperty.call(bilan_comptable, 'bilan_comptable')){
      filteredBalanceAgee = bilan_comptable["bilan_comptable"].filter((ecriture) => (ecriture.numero_compte).startsWith(selectedTiers));
    }
  
  
  console.log("Filtered Balance Agée: ",filteredBalanceAgee)*/
  console.log("Bilan Comptable: ",bilan_comptable)
  console.log("Bilan Comptable Keys: ",Object.keys(bilan_comptable))
  //console.log("Bilan Comptable elements: ",bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"])


  const getBalance = (data) => {
    setYear(data)
    setLoad(true)
    return data
  }

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Bilan : <span style={{ color: "#009688" }}>Comptable</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />

      {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
              <div>
                <select id="fruit" value={selectedTiers} onChange={(e) => setSelectedTiers(e.target.value)}>
                  <option value="">Selectionner</option>
                  <option value="ACTIF">Actif</option>
                  <option value="PASSIF">Passif</option>
                </select>
                <input type="number" onChange={(e) => getBalance(e.target.value)} placeholder='Ex:2024' />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <button onClick={onDownload}> Export excel </button>
                <button onClick={() => toPDF()}>Download PDF</button>
              </div>
            </div>
      )}


      <table id="customers" ref={myRef}>
        <tbody>
          {/***************************************** PASSIF ********************************************* */}
          {selectedTiers=="PASSIF"&&(
            <Bilan_Passif bilan_comptable={bilan_comptable} isLoadingCode={isLoadingCode} load={load}/>
          )}
          {/********************************************************************************************* */}
          {/***************************************** ACTIF ********************************************* */}
          {selectedTiers=="ACTIF"&&(
          <Bilan_Actif bilan_comptable={bilan_comptable} isLoadingCode={isLoadingCode} load={load}/>
          )}
          {/********************************************************************************************* */}
        </tbody>
      </table>

    </div>
  )
}
