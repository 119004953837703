import React, { useState, useEffect } from 'react';
import InputDetail from 'components/Inputs/InputDetail';
import { toast } from 'react-toastify';
import ButtonP from 'components/buttons/ButtonP';
import { useGetJournalIdQuery, useGetTiersByDossierClientQuery } from 'actions/sage_clone/journaux/queries';
import {  useUpdateLivreJournalMutation } from 'actions/sage_clone/journaux/mutations';
import Ecriture from './Ecriture/Ecriture';
import { useGetCodesQuery } from 'actions/comptabilite/queries';
import { useGetCodeJournalQuery } from 'actions/sage_clone/code_journaux/queries';
//import Etat from './Etats/GenererEtats';
import { useGetEcritureByLivreIdQuery } from 'actions/sage_clone/ecritures/queries';
import { useLocation } from 'react-router-dom'
import "./enregistrer.css"
import { FaArrowDown,FaArrowRight } from 'react-icons/fa';
import ClientModal from 'pagesSAGECOMPTA/Saisie/Ecriture/client/ClientModal';
//import AddPieceJointe from './Ecriture/AddPieceJointe';

function SaisieOCR() {

  const {state} = useLocation()
  const [facture, setFacture] = useState([]);

  //console.log("L'ensemble des ecritures: ",state.ecrituresOCR)

  //Les codes
  const {data: codesResponse = []} = useGetCodesQuery({dossier_client:state.dossierId});
  const {data: codesTiersResponse=[], refetch: refetch1} = useGetTiersByDossierClientQuery({dossier_id:state.dossierId})

  const {
      data: codeJournalResponse= [],
    } = useGetCodeJournalQuery();
   
  const {
    data: journalData,
    isFetching,
    error,
    refetch
  } = useGetJournalIdQuery({ id: state.journalId })

  const [updateClient] = useUpdateLivreJournalMutation();

  const {data: ecritures} = useGetEcritureByLivreIdQuery({
    livre_journal: state.journalId,
  })

  const [montrer,setMontrer] = useState(false)


  useEffect(() => {
    refetch()
    refetch1()
  },[ecritures, codesTiersResponse])

  const [clientDetails, setClientDetails] = useState({
    numero_facture: '',
    reference: '',
    date: new Date(),
    total_credits: 0,
    total_debits: 0
  });


  useEffect(() => {
    if (journalData) {
      setClientDetails(journalData?.data[0]);
    }
    if(state.ecrituresOCR){
      var elements = []
      state.ecrituresOCR.forEach((item,index)=>{
        elements.push({
          id: index+1,
          compte_general: 0,
          compte_tiers: 0,
          compte_auxiliaire: 0,
          libelle: item.description,
          date: "",
          debit: item.unitPrice,
          credit: 0,
          livre_journal: state.journalId,
          dossier_client: state.dossierId,
          clickRow:false
        })
      })
      setFacture(elements)
    }
  }, [journalData]);


  const saveChanges = async () => {
    try {
      await updateClient({
        id: state.journalId,
        ...clientDetails,
        file:null
      }).unwrap();
      toast.success('Mise à jour réussie.');
      
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour."
      );
    }
  };

  if (isFetching) return <div>Chargement...</div>;
  if (error) return <div>Une erreur s&#39;est produite.</div>;

  const showDetail = () => {
    setMontrer(c => !c)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClient, setIsClient] = useState(true)
    
    const closeModal = () => {
        setIsModalOpen(false)
      };

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div className="header-title" style={{padding: "15px", flex: 1}}>
           <div style={solde}>
              <h4 className="titre-dossier">
                Journal <span style={{color:"#009688"}}>{state.title}</span>  : {clientDetails.reference || 'Inconnu'}
              </h4>
           </div>
          <span style={text}>Vous pouvez ici visualiser ou modifier les détails du client.</span>
      </div>
      
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
        className="d-flex flex-column gap-2"
        style={{ padding: '15px' }}
      >
        
        <div className="row">
          <div className="col-md-3">
            <InputDetail
              label="Numéro Facture"
              name="numero_facture"
              value={clientDetails.numero_facture}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  numero_facture: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-3">
            <InputDetail
              label="Référence"
              name="reference"
              value={clientDetails.reference}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  reference: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-3">
            <InputDetail
              type="date"
              label="Date d'échéance"
              name="date"
              value={clientDetails.date}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  date: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          
          <div className="col-md-3 pt-3">
            <ButtonP type="submit" text="Modifier" />
          </div>
        </div>


      </form>
        {/* Ajouter une ecriture comptable */}
        <hr />
        <button onClick={showDetail}>Voir les détails comptables de la facture {" "}

          {
            montrer?<FaArrowDown width={15}/>:<FaArrowRight width={15}/>
          }
        </button>
        <div className={`${montrer?"show":"cacher"}`}>
          {state.ecrituresOCR.map((ecriture,index) => {
            return (
              <div key={index}>
                  <h4>{ecriture.description}</h4>
                  <div>
                    <span>Quantité:</span><strong>{ecriture.quantity}</strong>
                  </div>
                  <div>
                    <span>Prix Hors Taxe :</span><strong>{ecriture.amount}</strong>
                  </div>
                  <div>
                    <span>Coût de la Taxe:</span><strong>{ecriture.amount_taxe}</strong>
                  </div>
                  <div>
                    <span>Prix TTC:</span><strong>{ecriture.amount_ttc}</strong>
                  </div>
                  <hr />
              </div>
            )
          })}
        </div>
        <Ecriture
          codes={codesResponse || []}
          codesTiers={codesTiersResponse || []}
          codeJournalResponse={codeJournalResponse.data || []}
          livre_journal={state.journalId}
          factures={facture} 
          setFacture={setFacture}
          ecrituresOCR={state.ecrituresOCR}  
          dossier_client={state.dossierId}  
          setIsModalOpen={setIsModalOpen}
          refetchCodesTiers={refetch1}
          setIsClient={setIsClient}
          refetchJournal={refetch}
        >
          <h2
            style={{color: " #009688", fontSize: "20px", fontWeight: 700}}>
            &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
          </h2>
        </Ecriture>

        <ClientModal 
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          refetch={refetch} 
          refetchCodesTiers={refetch1}
          isClient={isClient}
        />
        <hr />
        {/**********************************/}

    </div>
  );
}




const solde = {
  fontFamily: "Montserrat",
  fontSize: "35px",
  fontWeight: "600",
  marginTop: "10px",
};

const text = {
  fontFamily: "Montserrat",
  fontWeight: "400",
  fontSize: "13px",
};

export default SaisieOCR;

