// Modal.js
import React, {useEffect, useState} from "react";
import "./ecriture.css";
// import { useDispatch } from "react-redux";
import {toast} from "react-toastify";
//import ButtonP from 'components/buttons/ButtonP';
import { Col, Container, Form, Row} from "react-bootstrap";
import { useCreateEcritureComtableMutation, useDeleteEcritureComtableMutation, useUpdateEcritureComtableMutation } from "actions/sage_clone/ecritures/mutations";
import { useGetEcritureByLivreIdQuery } from "actions/sage_clone/ecritures/queries";
//import Select from "react-select";
import Creatable from 'react-select/creatable';
import { useNavigate } from "react-router-dom";


const Ecriture = ({
  codes,
  codesTiers,
  codeJournalResponse,
  livre_journal,
  factures, 
  setFacture,
  dossier_client,
  refetchCodesTiers
}) => {
  //const { clientID: id } = useParams();
  const navigate = useNavigate();
  const {data: ecritures,refetch} = useGetEcritureByLivreIdQuery({
    livre_journal: livre_journal,
  })
  console.log("Les ecritures: ", ecritures);
  const customFormatCreateLabel = (inputValue) => `Créer "${inputValue}"`;

  const [ isAlertVisibleDebit, setIsAlertVisibleDebit ] = useState(false);
  const [ isAlertVisibleCredit, setIsAlertVisibleCredit ] = useState(false);
  const [ isAlertVisibleTier, setIsAlertVisibleTier ] = useState(false);

  const [addLivreJournal] = useCreateEcritureComtableMutation()
  const [updateLivreJournal] = useUpdateEcritureComtableMutation()
  const [deleteLivreJournal] = useDeleteEcritureComtableMutation()

  const options = codes.map((code) => {
    return {
      value: parseInt(code.code), label: code.combine, libelle: code.libelle
    }
  })

  /*const optionsTiers = codesTiers.map((code) => {
    return {
      value: parseInt(code.compte_comptable), label: code.compte_comptable + "-" + code.nom
    }
  })*/

  console.log("codes journaux: ",codeJournalResponse,"\nLes codes: ",codes)
  const [mesOptionsTiers,setOptionsTiers] = useState(null)
    const customNoOptionsMessage = () => {
    return <div style={{fontSize:"11px",color:"#ffcc00"}}>{`"Aucun compte tiers n'est associé à ce compte générale"`}</div>
  };


  console.log("Mon tiers: ",mesOptionsTiers)

  useEffect(() => {
  factures.forEach((facture) => {
    if(facture.compte_general != 0){
      const options = codesTiers.filter((code) => code.compte_generale.includes(facture.compte_general))
                                .map((code) => ({
                                  value: parseInt(code.compte_comptable), label: `${code.compte_comptable}-${code.nom}`
                                }));
      setOptionsTiers(options);
      if(options.length === 0){
        setIsAlertVisibleTier(true);
        setTimeout(() => setIsAlertVisibleTier(false), 3000);
      }
    }

    if(facture.credit > 0){
      setIsAlertVisibleDebit(true);
      setTimeout(() => setIsAlertVisibleDebit(false), 3000);
    }
    
    if(facture.debit > 0){
      setIsAlertVisibleCredit(true);
      setTimeout(() => setIsAlertVisibleCredit(false), 3000);
    }
  });
  if(codesTiers.length>0){
    refetchCodesTiers()
  }
}, [factures,codesTiers]);




  // Handle input changes
  const handleInputChange = (event, id) => {
  const { name, value, type, checked } = event.target;
  setFacture((prevFactures) =>
    prevFactures.map((facture) =>
      facture.id === id
        ? { ...facture, [name]: type === "checkbox" ? checked : type === "date" || name === "libelle" ? value : parseInt(value, 10) }
        : facture
    )
  );
};


  const resetFacture = (id) => {
  setFacture((prevFactures) =>
    prevFactures.map((facture) =>
      facture.id === id
        ? { ...facture, compte_general: 0, compte_tiers: 0, compte_auxiliaire: 0, libelle: "", date: "", debit: 0, credit: 0, clickRow: false }
        : facture
    )
  );
};


  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    for (const facture of factures) {
      const requiredFields = ["libelle"];
      const areFieldsFilled = requiredFields.every((field) => facture[field] && facture[field].toString().trim() !== "");
      if (!areFieldsFilled) {
        toast.error("Tous les champs sont obligatoires.");
        return;
      }

      const formData = new FormData();
      formData.append("compte_general", facture.compte_general);
      formData.append("compte_auxiliaire", facture.compte_auxiliaire);
      formData.append("compte_tiers", facture.compte_tiers);
      formData.append("libelle", facture.libelle);
      formData.append("debit", facture.debit);
      formData.append("credit", facture.credit);
      formData.append("date", facture.date);
      formData.append("livre_journal", facture.livre_journal);
      formData.append("dossier_client", facture.dossier_client);

      try {
        await addLivreJournal({
          livre_journal: facture.livre_journal,
          libelle: facture.libelle,
          compte_general: facture.compte_general,
          compte_tiers: facture.compte_tiers,
          compte_auxiliaire: facture.compte_auxiliaire,
          debit: facture.debit,
          date: facture.date,
          credit: facture.credit,
          dossier_client: facture.dossier_client,
          formData: formData
        }).unwrap();
        resetFacture(facture.id);
        toast.success("Écriture ajoutée avec succès");
        //refetch();
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(`Erreur: Champ requis non remplis ou type de donnée non respecté`);
      }
    }

    navigate(`/client/${dossier_client}/comptabilite/details/${livre_journal}/`);
  };


 
  const colourStyles = {
    option: (styles, { data}) => {
      return {
        ...styles,
        fontFamily: data.label,
        fontSize: "12px"
      };
    },

    singleValue: (styles) => ({
      ...styles,
      fontSize: "12px"
    })
  };

  const handleModify = async (id) => {
  const facture = factures.find(facture => facture.id === id);
    try {
      await updateLivreJournal({
        id: facture.id,
        ...facture,
      }).unwrap();
      toast.success("Écriture modifiée avec succès");
      resetFacture(facture.id);
      refetch();
    } catch (error) {
      toast.error(`Erreur de mise à jour: ${error.data?.error || error.message}`);
      console.error('La requête a échoué:', error);
    }
  };


  const handleSuppress = async (id) => {
    try {
      await deleteLivreJournal({ id });
      toast.success('Écriture supprimée avec succès');
      refetch();
    } catch (error) {
      toast.error(`Erreur de suppression`);
    }
};


    const onChangeTier = (e) => {
    if(e!=null){
      setFacture((prevState) => ({
        ...prevState,
        compte_tiers: e.value || null,
        compte_auxiliaire: 0
      }))
    }
  }
    const handleCreateRole = (e,compte) => {
      if(e!=null){
        if (compte.toString().startsWith("411") ||
            compte.toString().startsWith("412") ||
            compte.toString().startsWith("413") ||
            compte.toString().startsWith("414") ||
            compte.toString().startsWith("416") ||
            compte.toString().startsWith("418") ||
            compte.toString().startsWith("419")
            ){
          return navigate(`/client/${dossier_client}/client/`,{state:{comeFrom:true}})
        }
        else if (compte.toString().startsWith("401") ||
                 compte.toString().startsWith("402") ||
                 compte.toString().startsWith("404") ||
                 compte.toString().startsWith("408") ||
                 compte.toString().startsWith("409")
                ){
          return navigate(`/client/${dossier_client}/fournisseurs/`, {state:{comeFrom:true}})
        }
        else if (compte.toString().startsWith("421") || 
                 compte.toString().startsWith("423") || 
                 compte.toString().startsWith("424") ||
                 compte.toString().startsWith("425") ||
                 compte.toString().startsWith("426") ||
                 compte.toString().startsWith("428") 
                ){
          return navigate(`/client/${dossier_client}/salaries/`, {state:{comeFrom:true}})
        }
      }
      
    }

  return (
    <div>
      <div className="container-background w-100 w-md-75 px-0">
        <Container fluid className="container-style p-4 mb-5">
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="d-flex flex-column align-items-center gap-2">
              <h2>Saisir des écritures comptables</h2>
              <div className="w-100 d-flex flex-column gap-3">
                {factures.map((facture, index) => (
                  <div key={index} className="w-100">
                    <Row className="row-gap-2 w-100" style={{ justifyContent: "center" }}>
                      <Col sm={2}>
                        <Form.Group controlId={`formDate-${facture.id}`}>
                          <Form.Label className="mb-2">Date*</Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={facture.date}
                            onChange={(e) => handleInputChange(e, facture.id)}
                            className="rounded rounded-pill"
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={2}>
                        <Form.Group controlId={`formCompteGeneral-${facture.id}`}>
                          <Form.Label className="mb-2">Compte*</Form.Label>
                          <Creatable
                            options={options}
                            onChange={(e) => handleInputChange({ target: { name: 'compte_general', value: e.value } }, facture.id)}
                            styles={colourStyles}
                          />
                        </Form.Group>
                      </Col>

                      {/*<Col sm={2}>
                        <Form.Group controlId={`formCompteAuxiliaire-${facture.id}`}>
                          <Form.Label className="mb-2">Auxiliaire P/C</Form.Label>
                          <Form.Control
                            type="number"
                            name="compte_auxiliaire"
                            value={facture.compte_auxiliaire}
                            onChange={(e) => handleInputChange(e, facture.id)}
                            className="rounded rounded-pill hide"
                            disabled={facture.compte_auxiliaire == 0 ? true : false}
                          />
                        </Form.Group>
                      </Col>*/}

                      <Col sm={2}>
                        <Form.Group controlId={`formAuxiliaireTiers-${facture.id}`}>
                          <Form.Label className="mb-2 fs">Auxiliaire Tiers</Form.Label>
                          {/*<Creatable
                            options={mesOptionsTiers}
                            name="tiers"
                            formatCreateLabel={customFormatCreateLabel}
                            onChange={(e) => handleInputChange({ target: { name: 'compte_tiers', value: e.value } }, facture.id)}
                            styles={colourStyles}
                            isDisabled={facture.compte_general != 0 ? false : true}
                            noOptionsMessage={customNoOptionsMessage}
                            isClearable={true}
                          />*/}
                          <Creatable 
                              options={mesOptionsTiers} 
                              isClearable 
                              name="tiers"
                              formatCreateLabel={customFormatCreateLabel}
                              onChange={(e) => onChangeTier(e)}
                              isDisabled={facture.compte_general!=0?false:true}
                              styles={colourStyles}
                              noOptionsMessage={customNoOptionsMessage}
                              onCreateOption={(e) => handleCreateRole(e,facture.compte_general)}
                            />
                          {isAlertVisibleTier && <div style={{ backgroundColor: "#ffcc00", fontSize: "10px", padding: "5px", borderRadius: "8px", marginTop: "5px" }}>Aucun compte tiers n&apos;est associable à ce compte générale </div>}
                        </Form.Group>
                      </Col>

                      <Col sm={2}>
                        <Form.Group controlId={`formLibelle-${facture.id}`}>
                          <Form.Label className="mb-2">Libellé*</Form.Label>
                          <Form.Control
                            type="text"
                            name="libelle"
                            value={facture.libelle}
                            onChange={(e) => handleInputChange(e, facture.id)}
                            className="rounded rounded-pill"
                            style={{ fontSize: "11px", padding: "10px 5px" }}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={2}>
                        <Form.Group controlId={`formDebit-${facture.id}`}>
                          <Form.Label className="mb-2">Débit</Form.Label>
                          <Form.Control
                            type="number"
                            name="debit"
                            value={facture.debit}
                            onChange={(e) => handleInputChange(e, facture.id)}
                            className="rounded rounded-pill"
                            disabled={facture.debit == 0 && facture.credit > 0 ? true : false}
                          />
                          {facture.debit == 0 && facture.credit > 0 && isAlertVisibleDebit && <div style={{ backgroundColor: "#ffcc00", fontSize: "10px", padding: "5px", borderRadius: "8px", marginTop: "5px" }}>Débit désactivé car le crédit est différent de zéro</div>}
                        </Form.Group>
                      </Col>

                      <Col sm={2}>
                        <Form.Group controlId={`formCredit-${facture.id}`}>
                          <Form.Label className="mb-2">Crédit</Form.Label>
                          <Form.Control
                            type="number"
                            name="credit"
                            value={facture.credit}
                            onChange={(e) => handleInputChange(e, facture.id)}
                            className="rounded rounded-pill"
                            disabled={facture.credit == 0 && facture.debit > 0 ? true : false}
                          />
                          {facture.credit == 0 && facture.debit > 0 && isAlertVisibleCredit &&
                            <div style={{
                              backgroundColor: "#ffcc00",
                              fontSize: "10px",
                              padding: "5px",
                              borderRadius: "8px",
                              marginTop: "5px"
                            }}>
                              Crédit désactivé car le débit est différent de zéro
                            </div>}
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                ))}

                <Row className="row-gap-2 w-100" style={{ justifyContent: "center" }}>
                  <Col sm={6}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {!factures.some(f => f.clickRow) &&
                        <button
                          type="submit"
                          className="btn btn-primary rounded rounded-pill px-4"
                          style={{
                            width: "200px",
                            backgroundColor: "#068f7a",
                            borderColor: "#068f7a",
                          }}
                          disabled={
                            factures.some(f => !f.libelle || !f.date)
                          } // Add other fields as necessary
                        >
                          Ajouter
                        </button>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "20px", marginLeft: "-10px" }}>
              {factures.some(f => f.clickRow) &&
                <button
                  onClick={() => handleModify()}
                  className="btn btn-primary rounded rounded-pill px-4"
                  style={{
                    backgroundColor: "#ffbf00",
                    borderColor: "#daa520",
                    width: "200px",
                  }}
                  disabled={
                    factures.some(f => !f.libelle || !f.date)
                  } // Add other fields as necessary
                >
                  Modifier
                </button>
              }

              <button
                onClick={() => handleSuppress()}
                className="btn btn-primary rounded rounded-pill px-4"
                style={{
                  backgroundColor: "#c21807",
                  borderColor: "#7c0a02",
                  width: "200px",
                }}
                disabled={
                  factures.some(f => !f.libelle || !f.date)
                } // Add other fields as necessary
              >
                Suppression
              </button>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
};


export default Ecriture;
