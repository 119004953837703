// Modal.js
import React from "react";
import "../../components/Modal.css";
// import { useDispatch } from "react-redux";
import {useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCodesSalariesQuery} from "actions/comptabilite/queries";
import {useCreateSalariesMutation} from "actions/salaries/mutations";
import GeneriqueModal from "components/GeneriqueModal";
import ButtonCancel from "components/buttons/ButtonCancel";
import ButtonGreen from "components/buttons/ButtonGreen";

const ModalClient = ({isOpen, onClose, onDossierCreated, clients, refetch, retour}) => {
  const {clientID: id} = useParams();
  /*const {
    data: codes,
    refetch
  } = useGetCodesComptablesQuery();*/
   const navigate = useNavigate();

  const {data: codes = []} = useGetCodesSalariesQuery();

  console.log("Je vous affiche les clients: ", clients);
  console.log("Les numéros de compte: ", codes);

  const [creationClient, {isLoading}] = useCreateSalariesMutation();
  const {
    register,
    handleSubmit,
    //setValue,
    formState: {errors},
    reset,
  } = useFormContext();

  const onSubmit = (data) => {
    const clientData = {...data, 
      dossier_id: id,
      email: data.email?.value || "aucun@gmail.com",
      telephone: data.telephone?.value || "777777777",
      adresse: data.adresse?.value || "dakar"
    
    };
    creationClient(clientData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("Création salarié réussi !");
        reset();
        refetch();
        onDossierCreated();
        if(retour){
          navigate(-1)
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Une erreur est surevenue: " + error.message);
      });
  };

  return (
    <>
      <GeneriqueModal
        onClose={onClose}
        isOpen={isOpen}
        title="Ajouter un salarié">
        <form
          className="fournisseur-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="nom" className="form-label">
              Prénom et nom
            </label>
            <input
              className="form-controll"
              type="text"
              id="nom"
              placeholder="Prénom et nom"
              {...register("nom", {required: "Nom obligatoire"})}
            />
            {errors.nom && (
              <div className="text-danger">{errors.nom.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="telephone" className="form-label">
              Numéro de téléphone
            </label>
            <input
              className="form-controll"
              type="tel"
              id="telephone"
              name="telephone"
              placeholder="777777777"
              {...register("telephone", {
                maxLength: 9,
              })}
            />
            {errors.telephone && (
              <div className="text-danger">{errors.telephone.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="adresse" className="form-label">
              Adresse
            </label>
            <input
              className="form-controll"
              type="text"
              id="adresse"
              placeholder="Adresse"
              {...register("adresse")}
            />
            {errors.adresse && (
              <div className="text-danger">{errors.adresse.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              className="form-controll"
              type="email"
              id="email"
              placeholder="email@example.com"
              {...register("email")}
            />
            {errors.email && (
              <div className="text-danger">{errors.email.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="fonction" className="form-label">
              Fonction
            </label>
            <input
              className="form-controll"
              type="text"
              id="fonction"
              name="fonction"
              placeholder="La fonction du salarié"
              {...register("fonction", {
                required: "Fonction obligatoire",
                minLength: 4,
              })}
            />

            {errors.fonction && (
              <div className="text-danger">{errors.fonction.message}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="compte_comptable" className="form-label">
              Compte Comptable
            </label>
            <select
              name="compte_comptable"
              className="form-controll"
              id="compte_comptable"
              placeholder="Les comptes clients"
              {...register("compte_comptable", {
                required: "Compte comptable obligatoire",
                minLength: 4,
              })}>
              <option value="" selected disabled hidden>
                Selectionner votre compte salariés
              </option>
              {codes?.map((code_client, index) => (
                <option value={code_client.code} key={index}>
                  {code_client.combine}
                </option>
              ))}
            </select>
            {errors.compte_comptable && (
              <div className="text-danger">
                {errors.compte_comptable.message}
              </div>
            )}
          </div>

          <div className="modal-footer">
            <ButtonCancel text={"Annuler"} onClick={onClose} />
            <ButtonGreen
              text={isLoading ? "Chargement..." : "Enregistrer"}
              type={"submit"}
              disabled={isLoading}
            />
          </div>
        </form>
      </GeneriqueModal>
    </>
  );
};

export default ModalClient;
