import React, { useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import "../DisplayJournal/style.css"
import { useEffect } from "react";
import HeaderFirst from 'utils/HeaderFirst'
import { useParams } from 'react-router-dom';
import { useGetBalanceGeneralQuery } from 'actions/sage_clone/comptabilite/queries';
import { usePermissions } from 'hooks/usePermissions';

import BarLoader from "react-spinners/BarLoader"

export default function BalanceGenerale() {
  const { clientID } = useParams();
  const permissions = usePermissions();
  const [year,setYear] = useState(2025);
  const [load, setLoad] = useState(false)
  const { data: balance = {}, isLoading: isLoadingCode, refetch } = useGetBalanceGeneralQuery({
    id: 11,
    dossier: clientID,
    annee: year
  })

  console.log("My client ID is: ", clientID)
  console.log("Mon grand livre est: ", balance["balance"])
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Balance Générale table',
    sheet: 'Balance'
  })

  // pour pdf
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const myRef = useRef(null);

  //const [annee, setAnnee] = useState()

  useEffect(function () {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch()
    if(!isLoadingCode)
      setLoad(false)
  }, [isLoadingCode, balance])
  
  const getBalance = (data) => {
    setYear(data)
    setLoad(true)
    return data
  }
  return (
    <div style={{width:"100%"}}>
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Balance Comptable: <span style={{ color: "#009688" }}>Général</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />
      {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
          <div>
            <input type="number" onChange={(e) => getBalance(e.target.value)} placeholder='Filtrer par année' />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <button onClick={onDownload}> Export excel </button>
            <button onClick={() => toPDF()}>Download PDF</button>
          </div>
        </div>
      )}

      <table id="customers" ref={myRef}>
        <tbody>
          <tr>
            <th>Compte Comptable</th>
            <th>Libelle</th>
            <th>Débit</th>
            <th>Crédit</th>
            <th>Mouvement Debit</th>
            <th>Mouvement Crédit</th>
            <th>Solde Débiteur</th>
            <th>Solde Créditeur</th>
          </tr>
          {!isLoadingCode && !load?(
            <>
              {balance &&
                Object.prototype.hasOwnProperty.call(balance, 'balance') &&
                balance["balance"]?.map((ecriture, index) => {
                  return (
                    <tr key={index}>
                      <td>{ecriture.numero_comptable}</td>
                      <td>{ecriture.libelle}</td>
                      <td>{ecriture.debit}</td>
                      <td>{ecriture.credit}</td>
                      <td>{ecriture.mouvement_debit}</td>
                      <td>{ecriture.mouvement_credit}</td>
                      <td>{ecriture.solde_debiteur}</td>
                      <td>{ecriture.solde_crediteur}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <th>-</th>
                <th>Total</th>
                <th>{balance &&
                  Object.prototype.hasOwnProperty.call(balance, 'total_debits') &&
                  balance["total_debits"]}
                </th>
                <th>{balance &&
                  Object.prototype.hasOwnProperty.call(balance, 'total_credits') &&
                  balance["total_credits"]}
                </th>
                <th>-</th>
                <th>-</th>
                <th>{balance &&
                  Object.prototype.hasOwnProperty.call(balance, 'total_debiteur') &&
                  balance["total_debiteur"]}
                </th>
                <th>{balance &&
                  Object.prototype.hasOwnProperty.call(balance, 'total_crediteur') &&
                  balance["total_crediteur"]}
                </th>
              </tr>
            </>
          ):(
            <tr className="global fournisseur-detail-container"
            style={{
              backgroundColor: 'white',
              minHeight: '700px',
              borderRadius: '20px',
              padding: '15px',
              opacity: '0.5',
              position: 'relative'
              
            }}>
              <td 
                style={{
                  position: 'absolute',
                  top: '70%',
                  left: '50%',
                  colspan:"3"
                }}
              >
                <BarLoader />
              </td>
          </tr>

          )}


        </tbody>

      </table>
    </div>
  )
}
